@import url("https://fonts.googleapis.com/css?family=Josefin+Sans&display=swap");
@import url("https://fonts.googleapis.com/css?family=Fira+Mono&display=swap");

@tailwind base;

@tailwind components;

@tailwind utilities;

a {
  font-weight: bold;
}

p {
  padding-bottom: 10px;
}
